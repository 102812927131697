const splints = [
    {
        "Choice": "",
        "ani": "UlnarGutter2.png",
        "Type": "Wrist",
        "Alt_1": "",
        "Evaluation": "Coming soon!",
        "Order": 8,
        "Description": "Evaluation of ulnar gutter splints is currently under way...stay tuned!",
        "Splint": "Ulnar gutter splint",
        "Alt_2": ""
    },
    {
        "ani": "ThumbSpicaWrist.svg",
        "Type": "Thumb, wrist",
        "Evaluation": "Coming soon!",
        "Alt_1": "",
        "Splint": "Thumb spica wrist splint",
        "Choice": "",
        "Alt_2": "",
        "Order": 7,
        "Description": "Evaluation of thumb spica wrist splints is currently under way...stay tuned!"
    },
    {
        "Order": 2,
        "Choice": "B003DQILZE",
        "Splint": "Cock-up wrist splint",
        "Evaluation": "This is the splint or brace that many people buy when they have symptoms in their wrist or hand. It has a hard (usually metal) slat on the palm side and fasteners on the top. Comfort and quality may vary, but cost should also be taken into consideration because most cock-up splints will do the job just fine without spending a lot of money.",
        "Alt_2": "WMMUELLERWRIST",
        "Alt_1": "B077BPYK5S",
        "Type": "Wrist",
        "ani": "CockUp.png",
        "Description": "Used for many (but by no means all) conditions that cause wrist pain. The cock-up wrist splint keeps the wrist rigid, so many conditions that improve when the wrist is restrained from bending or straightening can get some relief with this type of splint. Notable exceptions are de Quervain's tenosynovitis and ulnar-sided wrist pain, as well as most conditions that cause pain in the thumb."
    },
    {
        "ani": "RadialGutter.svg",
        "Description": "Evaluation of radial gutter splints is currently under way...stay tuned!",
        "Order": 6,
        "Splint": "Radial gutter",
        "Choice": "",
        "Evaluation": "Coming soon!",
        "Alt_1": "",
        "Type": "Thumb, wrist",
        "Alt_2": ""
    },
    {
        "Alt_1": "B00GTXAFLG",
        "Choice": "B07CDCJF3R",
        "Order": 4,
        "ani": "ThumbCMC.svg",
        "Description": "The soft, gentle compression of a thumb CMC splint provides relief for most people with arthritis at the base of the thumb (thumb CMC arthritis).",
        "Alt_2": "B0069RJC0M",
        "Evaluation": "Splints that treat base of thumb arthritis come in three varieties; the type of splint that we find is usually the most helpful is one made from soft neoprene without any metal or plastic. A splint like this lets the thumb joint move a little bit and find a comfortable position while still reducing overall motion and giving it support. Other designs are entirely rigid, and some are designed to help to put the thumb joint back into its pre-arthritis position. Of course, all of these splints can be beneficial and figuring out which one is best requires a bit of trial and error....but we usually recommend to our patients that they start with neoprene splint first.",
        "Type": "Thumb",
        "Splint": "Thumb CMC splint"
    },
    {
        "Evaluation": "At first blush, buddy straps seem simple and basic without the need to buy any expensive options. And yes, simple 2-1/4\" wide athletic tape can be used very effectively…but the adhesive of tape can be very irritating to skin, and tape needs to be changed often. Consider buddy straps that use other types of fasteners to stay in place for greater durability and comfort.",
        "Order": 5,
        "Description": "Essentially these are thin straps that attach the affected finger to a longer (if possible) neighboring finger (the “buddy”); this allows it to bend and straighten normally while limiting side-to-side motion.",
        "Splint": "Buddy straps",
        "Choice": "B01DME2TBI",
        "Type": "Hand, fingers",
        "Alt_2": "B07R586JK4",
        "Alt_1": "B077JB41T3",
        "ani": "BuddyStrap.svg"
    },
    {
        "Order": 1,
        "Description": "3M™ Coban™ Self-Adherent Wrap and similar products from other manufacturers are soft, versatile rolled wraps similar to an ACE™ Elastic Bandage but it is able to stick to itself (but not to your skin), making it easy to apply and stay in place.",
        "Alt_2": "B07SXBJSQF",
        "ani": "Coban.svg",
        "Alt_1": "B07YL6B5MN",
        "Type": "Finger, thumb",
        "Choice": "B07CX1SJTJ",
        "Evaluation": "Self-adhering medical tape like 3M™ Coban™ Wrap is a soft, versatile wrap that does not stick to other surfaces like traditional tape but does stick to itself, making it easy to apply. Wrapping more than one layer onto a joint provides compression and support without adhesive. When used across a joint it can restrict motion and provides some joint stability, and a gentle turn or two can help relieve pain by decreasing motion.",
        "Splint": "Coban (3M&trade; Coban&trade; Wrap and others)"
    },
    {
        "Evaluation": "These are made from cloth (often neoprene) and give support to the ulnar (pinky finger) side of the wrist. Design and materials do play a bit of a role in these straps since they are meant to be used during activities, but they are generally inexpensive and easy to find.",
        "Description": "Different than a cock-up wrist splint (which prevents up and down movement of the wrist), the ulnar gutter strap specifically prevents movement of the wrist towards the small finger. It’s a simple strap that supports the wrist, making it easy to use and comfortable to wear during activities.",
        "Order": 3,
        "Splint": "Ulnar wrist strap",
        "ani": "UlnarGutter.svg",
        "Alt_2": "B0068QQZ4U",
        "Alt_1": "B0056PQV2K",
        "Choice": "B07BBL7Y19",
        "Type": "Wrist"
    }
];
export {
    splints
}