const painpoints = [
    {
        "Alt_1": "B077BPYK5S",
        "Condition": "Wrist tendonitis",
        "Alt_2": "WMMUELLERWRIST",
        "Points": [
            18,
            19,
            13
        ],
        "Splint": "Cock-up wrist splint",
        "Choice": "B003DQILZE",
        "Evaluation": "Wrist tendinitis can occur either on the top (dorsal side) or bottom (palmar side) of the wrist.  Either source of tendinitis is treated initially by immobilizing the wrist. Alternatively, a ganglion cyst can also produce pain in similar places.",
        "Tip": "Remember that splints should be snug but not too tight. Excess length of straps can (and should) be trimmed so they do not catch on clothing.",
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 425000000
        }
    },
    {
        "Points": [
            41,
            46,
            16,
            32
        ],
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 425000000
        },
        "Tip": "",
        "Choice": "B07CX1SJTJ",
        "Splint": "Coban (3M&trade; Coban&trade; Wrap and others)",
        "Alt_1": "B07YL6B5MN",
        "Alt_2": "B07SXBJSQF",
        "Condition": "Thumb IP arthritis",
        "Evaluation": "Pain in these regions is usually caused by a sprained thumb IP joint (knuckle) or arthritis. Significant instability of this joint requires a rigid splint, but pain can often be improved with a simple compression wrap."
    },
    {
        "Evaluation": "Pain in this region is often due to sprains of these joints; these are common sports injuries and swelling and pain can last for many weeks and even months in some cases.",
        "Condition": "PIP sprain",
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 424000000
        },
        "Choice": "B07R586JK4",
        "Splint": "Buddy straps",
        "Alt_1": "B01DME2TBI",
        "Tip": "Slide the loop of the buddy loop onto just the injured finger.  Then wrap the tail of the loop around the adjacent finger, back over the loop and secure the Velcro end.  Do not put two fingers into the loop.  In general, for ring and middle finger injuries, strap the middle and ring fingers together.  For index finger injuries, strap index and middle fingers together.  Try to put straps between the joints of your fingers to make motion easier.",
        "Points": [
            2,
            5,
            8,
            11,
            21,
            24,
            27,
            30
        ],
        "Alt_2": "N/A"
    },
    {
        "Condition": "Scaphoid injury",
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 425000000
        },
        "Points": [
            45,
            35
        ],
        "Evaluation": "Pain in this location should be evaluated by a physician to rule out injuries (such as fracture) of the scaphoid bone, which require specialized care.",
        "Splint": "Thumb spica wrist splint",
        "Alt_1": "",
        "Choice": "",
        "Tip": "Pain in the \"anatomic snuffbox\" (the triangular depression where the the back of the thumb meets the wrist) often indicates an injury such as a fracture of the scaphoid bone of the wrist. It is essential to be seen by a primary care doctor or hand surgeon to prevent improper healing.",
        "Alt_2": ""
    },
    {
        "Choice": "B003DQILZE",
        "Alt_2": "WMMUELLERWRIST",
        "Splint": "Cock-up wrist splint",
        "Points": [
            50
        ],
        "Condition": "Carpal tunnel syndrome",
        "Alt_1": "B077BPYK5S",
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 425000000
        },
        "Tip": "Remember that splints should be snug but not too tight. Excess length of straps can (and should) be trimmed so they do not catch on clothing.",
        "Evaluation": "Symptoms of carpal tunnel syndrome are fairly diffuse; they include numbness, sleepiness, or tingling in the thumb, index, and middle fingers (and sometimes the ring finger)—so not isolatable to a certain area. Keeping the wrist straight at night time during sleep can dramatically improve symptoms."
    },
    {
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 426000000
        },
        "Evaluation": "Ulnar-sided wrist pain is common and has numerous causes. These can include a tear of the cartilage on the ulnar (little finger) side of the wrist, called the triangular fibrocartilage complex and tendonitis of the extensor and flexor tendons on that side of the wrist. Treatment of pain in the side can either be rigid immobilization with an ulnar gutter splint or with a variety of straps used to provide stability to the ulnar side of the joint.",
        "Splint": "Ulnar wrist strap",
        "Alt_1": "B0056PQV2K",
        "Points": [
            49,
            37,
            40,
            17
        ],
        "Tip": "Strap used on the ulnar side of the wrist is a good start for ulnar-sided wrist pain, though it is not as effective as a hard splint.",
        "Alt_2": "B0068QQZ4U",
        "Condition": "Ulnar-sided wrist pain, TFCC Tear",
        "Choice": "B07BBL7Y19"
    },
    {
        "Choice": "B003DQILZE",
        "Alt_1": "B077BPYK5S",
        "Alt_2": "WMMUELLERWRIST",
        "Condition": "Dorsal wrist pain",
        "Tip": "Remember that splints should be snug but not too tight. Excess length of straps can (and should) be trimmed so they do not catch on clothing.",
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 425000000
        },
        "Splint": "Cock-up wrist splint",
        "Evaluation": "A dorsal ganglion cyst can cause pain directly in the middle of the dorsal (back) side of the wrist.  It often (but not always) produces a lump that can be felt over the top of the wrist.",
        "Points": [
            38
        ]
    },
    {
        "Choice": "B01BOTTFRE",
        "Tip": "In a pinch, Coban (3M&trade; Coban&trade; Wrap and others) can also be used to wrap the MP (middle) joint of the thumb. ",
        "Condition": "Thumb MP sprain",
        "Alt_1": "",
        "Splint": "MP splint (firm)",
        "Evaluation": "Pain in the region of the middle joint of the thumb, the metacarpal phalangeal joint, is oftentimes caused by a sprain.",
        "Points": [
            15,
            33,
            42
        ],
        "Alt_2": "",
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 425000000
        }
    },
    {
        "Condition": "Volar wrist pain",
        "Splint": "Cock-up wrist splint",
        "Choice": "B003DQILZE",
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 426000000
        },
        "Alt_1": "B077BPYK5S",
        "Evaluation": "Volar ganglion cyst is somewhat off-center but on the palmar side of the wrist. It often produces a bump or mass which can be felt or palpated and often causes pain in this region.",
        "Tip": "Remember that splints should be snug but not too tight. Excess length of straps can (and should) be trimmed so they do not catch on clothing.",
        "Points": [
            13
        ],
        "Alt_2": "WMMUELLERWRIST"
    },
    {
        "Condition": "Trigger finger",
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 425000000
        },
        "Alt_1": "B07YL6B5MN",
        "Alt_2": "B07SXBJSQF",
        "Tip": "Wrap the finger from the base of the nail to the bottom of the finger in a spiral manner, overlapping about 50%.  There is very little tension in the tape.  Monitor finger tip for evidence that compression is too high.  The purpose of this wrap is to prevent the finger from bending.  This wrap is typically worn just at night, but for severe triggering, day time wear is sometimes effective.",
        "Points": [
            3,
            6,
            9,
            12
        ],
        "Choice": "B07CX1SJTJ",
        "Splint": "Coban (3M&trade; Coban&trade; Wrap and others)",
        "Evaluation": "Pain in this region accompanied by clicking or locking of the finger (or sometimes difficulty with bending) can be a trigger finger which is tendonitis of the finger. Gently wrapping the affected finger during the night (while sleeping) can help symptoms for some people."
    },
    {
        "Condition": "Thumb CMC arthritis",
        "Evaluation": "Pain at the base of the thumb (where the thumb meets the wrist) is common. The most common source of pain is arthritis in this joint, called the thumb CMC (carpometacarpal) joint. Although sprains can occur, this is a much likely source of pain.",
        "Splint": "Thumb CMC splint",
        "Alt_1": "B00GTXAFLG",
        "Choice": "B07CDCJF3R",
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 425000000
        },
        "Tip": "There are softer splints (often made from neoprene) that provide gentle compression but also harder splints that are more stiff and rigid. Some provide stability while others provide warmth and comfort. There is no right answer for the treatment of thumb arthritis, but we have found the recommended splints to be the most effective. A bit of trial and error is in order to find the best solution.",
        "Alt_2": "B0069RJC0M",
        "Points": [
            14,
            34,
            43,
            48
        ]
    },
    {
        "Alt_2": "N/A",
        "Evaluation": "Pain in this region can be a sprain of the large knuckle or the MP joint (where your finger meets your hand); buddy strapping it to a neighboring finger can help these sprains heal.",
        "Points": [
            22,
            25,
            28,
            31
        ],
        "Tip": "Strap the injured finger to longest adjacent finger.",
        "Splint": "Buddy straps",
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 424000000
        },
        "Alt_1": "B01DME2TBI",
        "Condition": "MP sprain",
        "Choice": "B07R586JK4"
    },
    {
        "Alt_1": "",
        "Evaluation": "Pain on the thumb side of the wrist, directly over the bony prominence of the major wrist bone (the radius), is most consistent with a tendinitis of 2 tendons.  This is such a common source of pain and a common diagnosis that it has its own name, de Quervain's tenosynovitis.  It is sometimes called \"mommy thumb\" but this is less specific. Initial treatment requires splint immobilization that includes the radial side of the wrist as well as a portion of the thumb. Although a cock-up wrist splint is sometimes used, this type of splint is not nearly as effective for this condition as a radial gutter splint.",
        "Alt_2": "",
        "Points": [
            36,
            39,
            44
        ],
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 425000000
        },
        "Choice": "For internal use (not for Michael)",
        "Tip": "Sometimes this pain can be so intense that people feel like they have a broken bone. If you have pain in this region with a history of trauma, it is imperative to see a healthcare professional.",
        "Splint": "Radial gutter",
        "Condition": "De Quervain's tenosynovitis"
    },
    {
        "Tip": "Wrap the painful knuckle of your finger from the base of the nail bed in a spiral manner, overlapping about 80%.  There is very little tension in the tape.  You are creating a soft sleeve to support this joint.  Once applied gently squeeze and mold the cylinder of tape.",
        "Choice": "B07CX1SJTJ",
        "Evaluation": "Pain in these regions can be due to arthritis (which is common) or occasionally sprains of these joints; more serious issues like a break or dislocation is uncommon but can happen—and need to be treated by a medical professional—so be sure to have any deformity checked by a doctor.",
        "Alt_2": "B07SXBJSQF",
        "Splint": "Coban (3M&trade; Coban&trade; Wrap and others)",
        "Alt_1": "B07YL6B5MN",
        "Condition": "DIP arthritis",
        "Points": [
            1,
            4,
            7,
            10,
            20,
            23,
            26,
            29
        ],
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 424000000
        }
    },
    {
        "Choice": "B07CX1SJTJ",
        "Alt_1": "B07YL6B5MN",
        "Tip": "Pic of thumb wrapped; night time use only.",
        "Condition": "Trigger thumb",
        "Splint": "Coban (3M&trade; Coban&trade; Wrap and others)",
        "Alt_2": "B07SXBJSQF",
        "Evaluation": "Pain in this region is often accompanied by clicking or triggering of the thumb. Similar to trigger finger, if started early, wrapping the entire thumb with Coban (3M&trade; Coban&trade; Wrap and others) or tape can limit thumb motion at night during sleep and reduce symptoms of triggering for some people.",
        "createdAt": {
            "seconds": 1597697182,
            "nanoseconds": 426000000
        },
        "Points": [
            47
        ]
    }
];
export {
    painpoints
}