const conditions=[
    {
        "Alt_2": "B07YL6B5MN",
        "Condition": "PIP sprain",
        "ani": null,
        "Choice": "B07CX1SJTJ",
        "Alt_1": "B07SXBJSQF",
        "Order": 9,
        "Evaluation": "Self-adhering medical tape like 3M&trade; Coban&trade; Wrap is a soft, versatile wrap that does not stick to other surfaces like traditional tape but does stick to itself, making it easy to apply by wrapping more than one layer onto a joint in need of support. A gentle turn or two can help relieve the pain of a sprain by decreasing motion.",
        "Description": "A PIP sprain is what we call a sprain in the PIP joint (short for <i>proximal interphalangeal</i>) of one of the fingers. The PIP joint is the middle “knuckle” right at the halfway point of each finger. A “sprain” means that the joint became injured when it was stretched too far, as might happen playing sports or when someone falls on their hand. It’s also known as a “a jammed finger” for short."
    },
    {
        "ani": "ganglioncyst_1",
        "Condition": "Wrist ganglion cyst",
        "Choice": "B003DQILZE",
        "Description": "A wrist ganglion cyst is a fluid-filled lump that may occur either at the top (dorsal) or bottom (palmar or ventral) of any joint, but most commonly appears in the wrist. The lump is not harmful, but many cases a ganglion cyst may cause pain. The cyst might be big enough to see and feel through the skin, or it may be so small that it can only be seen on a medical test like an ultrasound or MRI. Many people find that using a splint to immobilize the wrist can help to reduce any pain.",
        "Alt_1": "WMMUELLERWRIST",
        "Alt_2": "B077BPYK5S",
        "Evaluation": "The splint used to help a wrist ganglion cyst is usually a simple “cock-up” wrist splint. This is the same splint that’s also used for carpal tunnel syndrome. And tennis elbow. And wrist tendonitis. And toenail fungus (well, maybe not the last one…but wouldn’t it be wonderful if it did?) The splint’s only job is to limit motion. In this case, limiting motion, especially what we call extension (e.g., moving the wrist away from the keyboard when typing) can help reduce pain. It also limits bending of the wrist, which can be helpful in reducing pain on the palm side. How does it help elbow pain? Stay tuned for our expanded offerings in the future!",
        "Order": 3
    },
    {
        "ani": "dequavian",
        "Choice": "HWDEQ4813",
        "Order": 4,
        "Alt_1": "",
        "Description": "Despite the fancy name, de Quervain's tenosynovitis is just a specific type of tendonitis. Tendonitis is the swelling of tendons, the tough bands tissue that connect muscle to bone. In de Quervain's tenosynovitis, the two wrist tendons near the base of the thumb are inflamed. It’s a very common condition, which it why it even gets its own name. It’s also known by a much less-fancy name—“mommy's thumb”—because it is common in mothers (and sometimes fathers!) from picking up and holding their new baby so much. Although some people get symptom relief from the more common “cock up” wrist splint, a specialized splint that immobilizes the thumb (called a <i>radial gutter splint</i>) is much more effective—especially when started early after the pain begins.",
        "Evaluation": "This radial gutter splint can be a bit more difficult to find at the average pharmacy. We haven’t seen many “over-the-counter” options that are comfortable and supportive, because what is required is a brace that supports part of the wrist and the thumb with the least amount of direct pressure or contact as possible. In our practice, we often see patients who “self-treat” with a cock-up wrist splint—but these don’t help much with de Quervain's tenosynovitis since the thumb tendons need to be held in place and cock-up splints do not do this.",
        "Condition": "De Quervain's tenosynovitis",
        "Alt_2": ""
    },
    {
        "Alt_2": "B00GTXAFLG",
        "Evaluation": "Splints that treat base of thumb arthritis come in three varieties; the type of splint that we find is usually the most helpful is one made from soft neoprene without any metal or plastic. A splint like this lets the thumb joint move a little bit and find a comfortable position while still reducing overall motion and giving it support. Other designs are entirely rigid, and some are designed to help to put the thumb joint back into its pre-arthritis position. Of course, all of these splints can be beneficial and figuring out which one is best requires a bit of trial and error....but we usually recommend to our patients that they start with neoprene splint first.",
        "Order": 1,
        "Alt_1": "B0069RJC0M",
        "Description": "Arthritis at the base of the thumb is one of the most common sources of thumb pain. It occurs when the CMC (carpometacarpal) joint breaks down so there is less cartilage at the ends of the bones. The CMC is all the way at the base of the thumb, inside the hand and almost at the wrist. It occurs here more than any other joint because of the large amount of work this joint does over the course of an average day. Arthritis is often (but not always) the cause of pain at the base of the thumb. The pain is often worse during grip activities such as opening a jar. Gentle compression and stabilization of the joint with a splint can help reduce pain. This condition is also known as <b>thumb CMC arthritis.</b>",
        "Condition": "Base of thumb (CMC) arthritis",
        "Choice": "B07CDCJF3R",
        "ani": "sprainedwrist"
    },
    {
        "Alt_1": "B07SXBJSQF",
        "Alt_2": "B07YL6B5MN",
        "Choice": "B07CX1SJTJ",
        "Order": 12,
        "ani": null,
        "Description": "Thumb IP arthritis is caused when the IP joint degrades so there is less cartilage at the ends of the bones. The IP joint (short for <i>interphalangeal</i>) is the big knuckle about halfway up the visible portion of the thumb. Gentle compression and stabilization of the joint can help reduce pain.",
        "Condition": "Thumb IP arthritis",
        "Evaluation": "Although the tips of the fingers can be cared for with a firm brace, a simple wrap made from several layers of self-adhering medical tape like 3M&trade; Coban&trade; Wrap. This may provide enough support to help relieve pain from arthritis. For conditions that require more rigid immobilization, such as mallet fingers or fractures, a hard splint is often more effective."
    },
    {
        "ani": "carpaltunnel_1",
        "Order": 2,
        "Alt_1": "WMMUELLERWRIST",
        "Choice": "B003DQILZE",
        "Evaluation": "The splint used to help carpal tunnel syndrome is usually a simple “cock-up” wrist splint. This is the splint or brace that many people buy when they have symptoms in their wrist or hand. It has a hard (usually metal) slat on the palm side and fasteners on the top. Comfort and quality may vary, but cost should also be taken into consideration because most cock-up splints will do the job just fine without spending a lot of money. What is it’s job? Simple: to keep the wrist from bending. It’s worn at night during sleep because most people sleep with their wrists bent, and that position can make it even worse—so keeping the wrist straight at night can help it feel better. Severe cases often require other treatment and should be cared for by a qualified orthopedist.",
        "Alt_2": "B077BPYK5S",
        "Description": "Carpal tunnel syndrome occurs when the median nerve (the main nerve of the forearm) is compressed by bones and ligaments in the wrist. This condition appears slowly over time and leads to numbness, tingling, and/or weakness in the hand or arm. Symptoms are often worse at night, or when gripping and carrying objects like an umbrella or the handlebars of a bicycle. Carpal tunnel syndrome can be extremely uncomfortable, and even painful. Over time, compression can lead to permanent damage to the nerve, which may be irreversible. Using a splint at night during sleep reduces wrist motion and may reduce the symptoms of carpal tunnel syndrome. If symptoms persist, the wrist should be evaluated and treated properly.",
        "Condition": "Carpal tunnel syndrome"
    },
    {
        "Condition": "Thumb CMC arthritis",
        "Description": "Thumb CMC arthritis is one of the most common sources of thumb pain. It occurs when the CMC (<i>carpometacarpal</i>) joint degrades so there is less cartilage at the ends of the bones. The CMC is all the way at the base of the thumb, inside the hand and almost at the wrist. It occurs here more than any other joint because of the large amount of work this joint does over the course of an average day. Arthritis is often (but not always) the cause of pain at the base of the thumb. The pain is often worse during grip activities such as opening a jar. Gentle compression and stabilization of the joint with a splint can help reduce pain. This condition is also known as <b>arthritis at the base of the thumb.</b>",
        "Evaluation": "Splints that treat base of thumb arthritis come in three varieties; the type of splint that we find is usually the most helpful is one made from soft neoprene without any metal or plastic. A splint like this lets the thumb joint move a little bit and find a comfortable position while still reducing overall motion and giving it support. Other designs are entirely rigid, and some are designed to help to put the thumb joint back into its pre-arthritis position. Of course, all of these splints can be beneficial and figuring out which one is best requires a bit of trial and error....but we usually recommend to our patients that they start with neoprene splint first.",
        "Order": 14,
        "Alt_2": "B00GTXAFLG",
        "ani": null,
        "Alt_1": "B0069RJC0M",
        "Choice": "B07CDCJF3R"
    },
    {
        "ani": "",
        "Alt_1": "",
        "Description": "A TFCC tear (<i>triangular fibrocartilage complex</i>…try saying that 3 times fast!) is a specific type of ulnar-sided wrist pain caused by an injury. These often occur when someone falls and lands on their hand, bending it backwards. It’s also Aaron’s favorite condition!",
        "Evaluation": "TFCC tears require more aggressive care than many other kinds of ulnar-sided wrist pain. A rigid splint is essential; however, these are difficult to find as an “over the counter” splint. It is important that any splint used for a TFCC tear has a piece of firm material that prevents motion of the wrist towards the small finger. ",
        "Choice": "B07BBL7Y19",
        "Condition": "TFCC tear",
        "Order": 6,
        "createdAt": {
            "seconds": 1598038246,
            "nanoseconds": 838000000
        },
        "id": "JVp1jvUh30UQyTC2FceC",
        "Alt_2": "B0056PQV2K"
    },
    {
        "Order": 13,
        "Choice": "B07CX1SJTJ",
        "Alt_2": "B07YL6B5MN",
        "Evaluation": "Self-adhering medical tape like 3M&trade; Coban&trade; Wrap is a soft, versatile wrap that does not stick to other surfaces like traditional tape but does stick to itself, making it easy to apply by wrapping more than one layer onto a joint in need of support. A gentle turn or two can help relieve the pain of a sprain by decreasing motion.",
        "Condition": "Thumb MP sprain",
        "Description": "A thumb MP sprain is what we call it a sprain in the MP joint (short for <i>metacarpophalangeal</i>) of the thumb. The MP joint in the thumb is the second “knuckle” down, where the thumb meets the palm of the hand. A “sprain” means that the joint became injured when it was stretched too far, as might happen playing sports (“skier’s thumb”) or when someone falls on their hand.",
        "Alt_1": "B07SXBJSQF",
        "ani": null
    },
    {
        "Evaluation": "Splints are commonly used for people with DIP arthritis. Although the tips of the fingers can be cared for with a firm brace, a simple wrap made from several layers of self-adhering medical tape like 3M&trade; Coban&trade; Wrap. This may provide enough support to help relieve pain from arthritis. For conditions that require more rigid immobilization, such as mallet fingers or fractures, a hard splint is preferable.",
        "Condition": "DIP arthritis",
        "Choice": "B07CX1SJTJ",
        "Order": 7,
        "Alt_2": "B07YL6B5MN",
        "Description": "Arthritis may happen in any joint and occurs when the cushion that protects the ends of bones wears down over time—often as people age. When severe, arthritis may cause pain and even deformity. DIP arthritis is what we call it when arthritis affects the DIP joint (short for (<i>distal interphalangeal(</i>); the DIP joint is the topmost joint or “knuckle” of the fingers, just below the nail. This is a very common site for arthritis.",
        "Alt_1": "B07SXBJSQF",
        "ani": null
    },
    {
        "Condition": "Trigger thumb",
        "Choice": "B07CX1SJTJ",
        "Description": "Trigger thumb is a case of trigger finger (<i>stenosing tenosynovitis</i>) that affects the thumb, causing the thumb to be stuck in a bent position. Trigger finger is often seen in people whose activities involve a repeated gripping action and is more common in women and people with diabetes.",
        "ani": null,
        "Order": 20,
        "Evaluation": "Self-adhering medical tape like 3M&trade; Coban&trade; Wrap is a soft, versatile wrap that does not stick to other surfaces like traditional tape but does stick to itself, making it easy to apply by wrapping more than one layer onto a joint in need of support. A gentle turn or two can help relieve the pain of a trigger thumb by decreasing motion.",
        "Alt_2": "B07YL6B5MN",
        "Alt_1": "B07SXBJSQF"
    },
    {
        "Alt_1": "",
        "Choice": "HWThumb4813",
        "ani": null,
        "Condition": "MP sprain",
        "Evaluation": "MP sprains are often cared for my immobilizing them. For the fingers, “buddy straps” may be used to attach the affected finger to a longer (if possible) neighboring finger; this allows it to bend and straighten normally while limiting side-to-side motion. For thumb MP sprains, self-adhering medical tape like 3M&trade; Coban&trade; Wrap is a soft, versatile wrap that does not stick to other surfaces like traditional tape but does stick to itself, making it easy to apply by wrapping more than one layer onto a joint in need of support. A gentle turn or two can help relieve the pain of a sprain by decreasing motion. Sometimes hard splints are also useful for this condition but are often custom molded by a hand therapist.",
        "Description": "An MP sprain is what we call a sprain in the MP joint (short for <i>metacarpophalangeal</i>) of one of the fingers. The MP joint on fingers other than the thumb is the 3rd “knuckle” down, where the finger meets the main part of the hand. For the thumb, it is the 2nd knuckle down, again where the thumb meets the main part of the hand. A “sprain” means that the joint became injured when it was stretched too far, as might happen playing sports or when someone falls on their hand.",
        "Order": 10,
        "Alt_2": ""
    },
    {
        "Choice": "B003DQILZE",
        "Alt_1": "WMMUELLERWRIST",
        "Description": "Dorsal wrist pain is pain along the back (or “dorsal”) side of the hand. It may also be called <i>dorsal wrist impingement syndrome.</i> This can lead to a pinching pain or discomfort when the wrist is bent backwards, due to inflammation of the joint. Anyone can get it, but it is commonly seen in athletics including yoga, gymnastics, and weightlifting.",
        "Order": 18,
        "ani": null,
        "Alt_2": "B077BPYK5S",
        "Evaluation": "The splint used to help dorsal wrist pain is usually a simple “cock-up” wrist splint. This is the same splint that’s also used for carpal tunnel syndrome. And tennis elbow. And a wrist tendonitis. And toenail fungus (well, maybe not the last one…but wouldn’t it be wonderful if it did?) The splint’s only job is to limit motion. In this case, limiting motion, especially what we call extension (e.g., moving the wrist away from the keyboard when typing) can help reduce pain. It also limits bending of the wrist, which can be helpful in reducing pain on the palm side. How does it help elbow pain? Stay tuned for our expanded offerings in the future!",
        "Condition": "Dorsal wrist pain"
    },
    {
        "Condition": "Volar wrist pain",
        "Alt_1": "WMMUELLERWRIST",
        "ani": null,
        "Choice": "B003DQILZE",
        "Description": "Volar wrist pain is not a single condition but refers to a range of conditions that may cause pain along the palm (or “volar”) side of the hand. These include tendonitis, arthritis, and some cases of carpal tunnel syndrome, among others.",
        "Evaluation": "The splint used to help volar wrist pain is usually a simple “cock-up” wrist splint. This is the same splint that’s also used for carpal tunnel syndrome. And tennis elbow. And a wrist tendonitis. And toenail fungus (well, maybe not the last one…but wouldn’t it be wonderful if it did?) The splint’s only job is to limit motion. In this case, limiting motion, especially what we call extension (e.g., moving the wrist away from the keyboard when typing) can help reduce pain. It also limits bending of the wrist, which can be helpful in reducing pain on the palm side. How does it help elbow pain? Stay tuned for our expanded offerings in the future!",
        "Order": 19,
        "Alt_2": "B077BPYK5S"
    },
    {
        "Description": "Ulnar-sided wrist pain is not just one condition but a group of them. All of them produce pain on the ulnar side of the wrist, meaning on the same side as the small finger. This is common among tennis players and quite specific in its location. Although the pain is in the region of the wrist, a typical “off the shelf” brace like a cock-up splint often does not help with ulnar-sided wrist pain. A more substantial ulnar gutter splint is much more effective and often more challenging to locate.",
        "Alt_2": "B0056PQV2K",
        "Condition": "Ulnar-sided wrist pain",
        "Order": 5,
        "Evaluation": "There are two different types of splints for ulnar-sided wrist pain: an <i>ulnar wrist strap</i> or an <i>ulnar gutter splint</i>. The ulnar wrist strap is more functional and easier to find. It’s a simple strap that supports the wrist, making it easy to use, comfortable to wear during activities, and often enough for many people to get relief. The ulnar gutter wrist splint is harder to find but may be more effective for some people. As with any splint, quality varies—but for most people this is a temporary addition to daily life. It is worn during work during activities. A cock-up wrist splint can be used in a pinch, but it will not work as well as an ulnar wrist strap or an ulnar gutter splint.",
        "ani": "Ulnar-sidedwristpain",
        "Choice": "B07BBL7Y19",
        "Alt_1": "B0068QQZ4U"
    },
    {
        "Order": 11,
        "Alt_2": "B07YL6B5MN",
        "Evaluation": "Self-adhering medical tape like 3M&trade; Coban&trade; Wrap is a soft, versatile wrap that does not stick to other surfaces like traditional tape but does stick to itself, making it easy to apply by wrapping more than one layer onto a joint in need of support. A gentle turn or two can help relieve the pain of a trigger finger by decreasing motion.",
        "ani": null,
        "Description": "Trigger finger is what we call it when a finger or the thumb gets stuck in a bent position. It’s also known as <i>stenosing (flexor) tenosynovitis</i>. Trigger finger is most common in the ring finger or thumb but may affect any finger. It is often seen in people whose activities involve a repeated gripping action and is more common in women and people with diabetes.",
        "Choice": "B07CX1SJTJ",
        "Condition": "Trigger finger",
        "Alt_1": "B07SXBJSQF"
    },
    {
        "Description": "Mallet finger occurs when there is damage to the tendon that usually keeps each finger straight, causing it to be bent. This might happen when something strikes it, like a mattress when changing a sheet or a baseball; in fact, it is sometimes called “baseball finger” for this reason.",
        "Alt_1": "",
        "Order": 8,
        "Alt_2": "",
        "ani": null,
        "Evaluation": "Mallet fingers require a rigid splint. The best splints are custom-made specifically for a certain patient’s fingers by a hand therapist. These will offer the most comfort and durability. In a pinch, an over-the-counter splint might help. A splint is usually worn all the time for 8 weeks so that the finger has time to heal.",
        "Choice": "B08422X1HC",
        "Condition": "Mallet finger"
    },
    {
        "Choice": "B003DQILZE",
        "Alt_1": "WMMUELLERWRIST",
        "Description": "Tendonitis is the swelling of tendons, the tough bands tissue that connect muscle to bone. Wrist tendonitis is the inflammation of tendons in the wrist (funny how that works). Wrist tendonitis may lead to pain and stiffness, especially upon waking up. The pain tends to be dull versus a sharp pain.",
        "Order": 15,
        "ani": null,
        "Evaluation": "The splint used to help wrist tendonitis is usually a simple “cock-up” wrist splint. This is the same splint that’s also used for carpal tunnel syndrome. And tennis elbow. And a wrist ganglion cyst. And toenail fungus (well, maybe not the last one…but wouldn’t it be wonderful if it did?) The splint’s only job is to limit motion. In this case, limiting motion, especially what we call extension (e.g., moving the wrist away from the keyboard when typing) can help reduce pain. It also limits bending of the wrist, which can be helpful in reducing pain on the palm side. How does it help elbow pain? Stay tuned for our expanded offerings in the future!",
        "Alt_2": "B077BPYK5S",
        "Condition": "Wrist tendonitis"
    }
];
export {
    conditions
}