<template>
  <div class="bg-white text-black">
    <div v-if="!user">
      <div
        class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
      >
        <div class="max-w-md w-full">
          <div>
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">Sign in</h2>
          </div>
          <form class="mt-8" @submit.prevent="login">
            <input type="hidden" name="remember" value="true" />
            <div class="rounded-md shadow-sm">
              <div>
                <input
                  aria-label="Email address"
                  name="email"
                  type="email"
                  v-model="email"
                  required
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                  placeholder="Email address"
                />
              </div>
              <div class="-mt-px">
                <input
                  aria-label="Password"
                  name="password"
                  type="password"
                  v-model="pass"
                  required
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                  placeholder="Password"
                />
              </div>
            </div>
            <div class="text-red-600 text-sm" v-text="error"></div>
            <div class="text-green-600 text-sm" v-html="status"></div>

            <div class="mt-6 flex items-center justify-between">
              <!-- <div class="flex items-center">
              <input
                id="remember_me"
                type="checkbox"
                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
              />
              <label
                for="remember_me"
                class="ml-2 block text-sm leading-5 text-gray-900"
              >Remember me</label>
              </div>-->

              <div class="text-sm leading-5">
                <a
                  href="#"
                  @click.prevent="forgotPW"
                  :disabled="!email"
                  class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >Forgot your password?</a>
              </div>
            </div>

            <div class="mt-6">
              <button
                type="submit"
                class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
              >
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container mx-auto mt-5">
        <div class="clearfix">
          <a
            href="#"
            @click.prevent="logout"
            class="float-right whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
          >Log out</a>
          <a
            href="#"
            @click.prevent="initdb"
            class="mx-5 float-right whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
          >Init DB</a>
        </div>
        <h1>Welcome, you ar logged in</h1>
        <div>
          <hr />
          <ul>
            <li v-for="pic in picList" :key="pic.name">
              <button class="btn" @click="showPic(pic.name)">{{ pic.name }}</button>
            </li>
          </ul>
          <hr />
          <div>
            <p>Upload an image to Firebase:</p>
            <input type="file" @change="previewImage" accept="image/*" />
            photo:``,
          </div>
          <div>
            <p>
              Progress: {{uploadValue.toFixed()+"%"}}
              <progress
                id="progress"
                :value="uploadValue"
                max="100"
              ></progress>
            </p>
          </div>
          <div v-if="imageData!=null || picture!=null">
            <img class="preview" :src="picture" />
            photo:``,
            <br />
            <button @click="onUpload">Upload</button>
          </div>
        </div>
      </div>

      <!-- Conditions -->
      <hr />
      <h1>Conditions</h1>
      <ul class="conditions-list">
        <li v-for="condition in conditions" :key="condition.id" class="conditions-item">
          <label class="conditions-item-label">{{condition.title}}</label>
          <!-- 

           <div v-if="currentlyEditing !== conditions.id">
            <button @click="editconditions(conditions)" class="conditions-button">
              <img src="../assets/pencil.svg" alt="Edit conditions" />
            </button>
            <button @click="deleteconditions(conditions)" class="conditions-button">
              <img src="../assets/trash.svg" alt="Delete conditions" />
            </button>
          </div>
          <form v-else class="edit-conditions-form">
            <label class="edit-conditions-label">
              Edit:
              <input type="text" v-model="conditionsEditText" class="edit-conditions-input" />
            </label>
            <button type="submit" class="edit-conditions-button" @click.prevent="updateTodoText()">Save</button>
          </form> 
          -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// https://csvjson.com/csv2json
import {
  auth,
  storage,
  conditionsCollection,
  splintsCollection,
  productsCollection,
  painPointsCollection,
  faqsCollection,
} from "../firebase";

import { products  } from "../data/products";
import { conditions  } from "../data/conditions";
import { splints  } from "../data/splints";
import { painpoints  } from "../data/painpoints";

export default {
  name: "experiment",
  firestore() {
    return {
    //   conditions: conditionsCollection.orderBy("createdAt", "desc"),
    //   splints: splintsCollection.orderBy("createdAt", "desc"),
    };
  },
  data() {
    return {
      email: "",
      pass: "",
      user: null,
      error: "",
      status: "",

      conditions: [],
      splints: [],

      imageData: null,
      picture: null,
      uploadValue: 0,
      picList: [],
    };
  },

  methods: {
    initdb() {
      var order = 0;
      productsCollection.get().then((snap) => {
        if (snap.size > 0) {
          console.info("products already filled",snap.size);
          return;
        } else {
          products.forEach((el) => {
            // console.log(el);
            el.createdAt = new Date();
            // console.log(el);
            productsCollection.add(el);
          });
          console.info("products added");
        }
      });

      painPointsCollection.get().then((snap) => {
        if (snap.size > 0) {
          console.info("painpoints already filled",snap.size);
          return;
        } else {
          painpoints.forEach((el) => {
            // console.log(el);
            el.createdAt = new Date();
            // console.log(el);
            painPointsCollection.add(el);
          });
          console.info("painpoints added");
        }
      });

      faqsCollection.get().then((snap) => {
        console.log(snap);
        if (snap.size > 0) {
          console.info("faqs already filled");
        } else {
          faqsCollection.add({
            question: `How do I wash my splint?`,
            answer: `Most off the shelf (OTS) splints can be surface cleaned with a damp cloth. A few can be washed by hand and air dried (example: comfort cool thumb cmc restriction splint). The manufacturer generally provides instructions. If not, stick with surface cleaning.`,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `Can my splint get wet? Can I wear it at the beach/pool?`,
            answer: `Most splints should not get wet while being used. Prolonged dampness against the skin is not healthy. Most splints are removed for showering.`,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `How many hours a day should I wear my splint?`,
            answer: `This depends on the injury. If there has been acute trauma, splints are usually worn full time and removed for showering and hand hygiene. If the splint is for treating a chronic condition such as arthritis (joint degeneration) it is worn during performance of activities that involve the painful joint. For carpal tunnel (a nerve compression injury), the splint is worn at night while sleeping.`,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `Should I take my splint off at night when I sleep?`,
            answer: `For acute injuries, splint are typically worn day and night. For chronic or degenerative injuries they are often removed at night. For carpal tunnel, the splint is worn only at night.`,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `How long should I wear a splint before it feels better?`,
            answer: `A splint is usually worn until the symptoms resolve. For chronic and degenerative conditions, splints are used for the duration, often on an “as needed” bases. Conditions are often episodic in nature.`,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `What’s the average life span of a splint? How often should I buy a new one?`,
            answer: `Splints are like clothing. They wear out. If the material becomes stretched out and is no longer providing good support, or if Velcro is no longer providing secure closure, the splint should be replaced. Amount of use will determine life span. Most OTS splints will last at least 6 months with full time use.`,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `What can I do if my hand is uncomfortable while in a splint?`,
            answer: `Your hand should not be uncomfortable in the splint. This usually indicates a poor or suboptimal fit. You can evaluate for small modifications that might help (eg, trimming an edge). Otherwise, you should seek professional advice from a Certified Hand Therapist.`,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `Can I use the same splint from a previous injury?`,
            answer: `If the splint needed for the two injuries is the same, absolutely!`,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `At what point should I see a doctor for my hand or wrist pain?`,
            answer: `Open wounds, anatomic deformity, severe bruising, loss of sensation, pronounced swelling, excessive pain, failure of symptoms to improve`,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `What kind of doctor treats hand and wrist pain?`,
            answer: `Orthopedic Hand Surgeon|Physiatrist Primary|Care Physician|Urgent Care `,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `Will my health insurance pay for wrist splints?`,
            answer: `This varies widely among carriers. Often insurance will cover one splint per year for chronic conditions, but this is with an MD’s prescription and with the splint provided by a facility (a hand therapy clinic, a doctor’s office, a prosthetics/orthotics facility). If you purchase it yourself, reimbursement is unlikely.`,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `Why should I trust your recommendations?`,
            answer: `Collectively we have many years of experience and have seen and used many different splints that are available. We have received feedback from patients who have used the splints. We are very familiar with predictable issues with splint design and materials. We are not aligned specific products. We have access to new products as they become available (attend conferences, continuing education). We can very effectively evaluate false claims based on our knowledge of the literature and science.`,
            order: ++order,
            createdAt: new Date(),
          });
          faqsCollection.add({
            question: `When do I need a custom splint?`,
            answer: `Support devices for body parts and joints range from the very simple (eg, an ace wrap or a piece of tape) to highly customized and complex (a specialized custom molded spine brace). Many common injuries can be treated with a simple, OTS support device. In these situations, the injured tissue is expected to recover with some light support and rest. More involved injuries that include tissue disruption such as bone fractures, tendon lacerations, nerve lacerations usually require a custom splint which is often fabricated by a Certified Hand Therapist based on referral from an MD. Custom splints are usually made out of a thermoplastic material that is heated up to soften it and then molded directly to the patient. Straps are added to hold the rigid device in place.`,
            order: ++order,
            createdAt: new Date(),
          });
          console.info("FAQs added");
        }
      });

      conditionsCollection.get().then((snap) => {
        if (snap.size > 0) {
          console.info("conditions already filled",snap.size);
        } else {
          conditions.forEach((el) => {
              console.log(el);
            conditionsCollection.add(el);
          });
          console.info("Conditions added");
        }
      });

      splintsCollection.get().then((snap) => {
        if (snap.size > 0) {
          console.info("splints already filled",snap.size);
        } else {
             splints.forEach((el) => {
              console.log(el);
            splintsCollection.add(el);
          });
          console.info("Splints added");
        }
      });

      console.info("All Done");
    },
    clearStatus() {
      this.error = "";
      this.status = "";
    },
    login() {
      this.clearStatus();
      auth
        .signInWithEmailAndPassword(this.email, this.pass)
        .then((user) => {
          window.user = this.user = user;
        })
        .catch((error) => {
          this.error = error.message;
        });
    },
    logout() {
      this.clearStatus();
      auth.signOut().catch((error) => {
        // An error happened.
        this.error = error.message;
      });
      this.user = window.user = null;
    },
    forgotPW() {
      this.clearStatus();
      auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.status =
            "Password Reset Email send to " +
            this.email +
            ".<br>Please check your inbox.";
        })
        .catch((error) => {
          this.error = error.message;
        });
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },

    showPic(pic) {
      //   console.log(pic);
      storage
        .ref()
        .child(pic)
        .getDownloadURL()
        .then((url) => {
          this.picture = url;
          //   console.log(url);
        });
    },
    onUpload() {
      this.picture = null;
      const storageRef = storage
        .ref(`${this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log("Upload Pic Error",error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.picture = url;
          });
          this.listStorage();
        }
      );
    },

    listStorage() {
      // Create a reference under which you want to list
      var listRef = storage.ref();
      this.picList = [];

      // Find all the prefixes and items.
      listRef
        .listAll()
        .then((res) => {
          res.items.forEach((itemRef) => {
            this.picList.push({ name: itemRef.name });
          });
        })
        .catch((error) => {
          console.error("List Storage Error",error.message);
        });
    },
  },
  mounted() {
    this.user = window.user;
    this.listStorage();
    // console.log(products);
    // this.conditions = conditionsCollection.orderBy("createdAt", "desc");
    // this.splints = splintsCollection.orderBy("createdAt", "desc");
  },
};
</script>

<style scoped="">
img.preview {
  width: 200px;
}
</style>