const products = [
    {
        "Style": "Good",
        "Description": "Easily tolerated splint",
        "Versatility": "Yes",
        "Washability": "Hand wash",
        "Affilate_URL": "https://amzn.to/3kaynlg",
        "IDX": "B0056PQV2K",
        "Comfort": "Good",
        "Colors": "Black",
        "Construction": "Fair.  Somewhat inconsistent",
        "Photo_3": "",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/61DF2ExK2zL._AC_SL1500_.png?alt=media&token=ca2302a0-ff66-476c-b655-8029a4654459",
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/81twyslKRwL._AC_SL1500_.png?alt=media&token=ce54dc1d-30be-424d-8d29-d4d7850599b2",
        "Ease_don_doff": "Good",
        "Value": "Good",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 765000000
        },
        "Durability": "Good",
        "Rating": 4,
        "Price": "$29.00",
        "Name": "WristWidget<sup>&reg;</sup>",
        "URL": "https://www.amazon.com/WristWidget-Patented-Adjustable-Triangular-Fibrocartilage/dp/B0056PQV2K/ref=sr_1_3?dchild=1&gclid=Cj0KCQjw9b_4BRCMARIsADMUIyqAPxK9TOxRybW8EV8i5ll4CMt_WhZiMNwiGRUfe-EL6kFOve-fZz4aAgXrEALw_wcB&hvadid=410022199410&hvdev=c&hvlocphy=9073479&hvnetw=g&hvqmt=e&hvrand=3609683391490688790&hvtargid=kwd-472713718261&hydadcr=21877_11241855&keywords=bullseye+wrist+brace&qid=1594932542&sr=8-3&tag=googhydr-20"
    },
    {
        "Affilate_URL": "https://amzn.to/3iyyYNh",
        "Construction": "Fair",
        "Photo_3": "",
        "Comfort": "Good when well fitted",
        "Ease_don_doff": "Fair.  Takes some practice",
        "Style": "Good",
        "Photo_2": "",
        "Value": "Good",
        "Washability": "Hand wash",
        "Versatility": "No",
        "IDX": "B07CDCJF3R",
        "Rating": 4,
        "URL": "https://www.amazon.com/Comfort-Cool-Restriction-Splint-Medium/dp/B07CDCJF3R/ref=sr_1_4?dchild=1&keywords=comfort+cool+thumb+brace&qid=1594083791&sr=8-4",
        "Name": "Neoprene Thumb Spica - Comfort Cool Thumb CMC Restriction Splint",
        "Price": "$29.36",
        "Durability": "Fair",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/81yvJuEIofL._SL1500_.png?alt=media&token=f3cc9ff6-82c9-4f96-b878-8aedcb983efe",
        "Colors": "Black, beige",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 763000000
        },
        "Description": "Dependable, well-designed, comfortable splint that provides support for a wide range of thumb CMC joint arthritis presentations"
    },
    {
        "Washability": "No wash",
        "Durability": "",
        "Price": "$11.89/pack of 12",
        "Construction": "",
        "Colors": "Multi colored pack",
        "Value": "",
        "Versatility": "Yes",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/81hQhftM4AL._AC_SL1500_.png?alt=media&token=d237dd1b-695a-46df-b5a3-14c9f1903214",
        "Name": "Self Adherent Wrap Cohesive Bandages ",
        "Description": "Generic option with reasonable performance for basic demands.",
        "Photo_3": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/61Zp2gcENRL._AC_SL1500_.png?alt=media&token=ed65b4f6-e212-404b-ad4b-bf266ba04a25",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 763000000
        },
        "Comfort": "",
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/51SOf3IMDJL._AC_SL1189_%20copy.png?alt=media&token=0494c90b-7935-4962-91ad-2c871a40ab9d",
        "Ease_don_doff": "",
        "IDX": "B07YL6B5MN",
        "URL": "https://www.amazon.com/gp/product/B07YL6B5MN/ref=ox_sc_act_title_1?smid=A9GA1QPC00JUP&psc=1",
        "Affilate_URL": "https://amzn.to/2RpLq65",
        "Rating": 4,
        "Style": ""
    },
    {
        "URL": "",
        "Description": "Well-designed, light support wrist wrap that includes base of thumb.",
        "Photo_2": "",
        "Affilate_URL": "",
        "Style": "Excellent.",
        "Colors": "",
        "Washability": "Machine wash gentle",
        "Photo_1": "",
        "Price": "$49.99",
        "IDX": "BLWWWT",
        "Construction": "Excellent.  High end option for wrist wrap",
        "Versatility": "No",
        "Value": "Expensive",
        "Photo_3": "",
        "Ease_don_doff": "Good",
        "Comfort": "Good",
        "Rating": 5,
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 764000000
        },
        "Durability": "Very good",
        "Name": "Push&reg; Care Wrist Brace"
    },
    {
        "Name": "Mars Wellness 1/2\" Wide Buddy Splint with Secure HEX Lining",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/WideFingerBuddySplint_.png?alt=media&token=78070fa3-a5cf-494c-bc5a-2609e29954be",
        "Washability": "",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 762000000
        },
        "Affilate_URL": "https://amzn.to/3hzMfnj",
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/71PyPEYct7L._AC_SL1500_.png?alt=media&token=0be80d42-6965-48ce-b04c-0c38ec893392",
        "Style": "Good",
        "IDX": "B077JB41T3",
        "Comfort": "Good",
        "Photo_3": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/71dQ-r0tyrL._AC_SL1500_.png?alt=media&token=922f04db-511e-4b4b-a582-933f9212805b",
        "Price": "$13.89/pack of 5",
        "Durability": "Good, although material has a limited lifespan by definition.",
        "Rating": 4,
        "Ease_don_doff": "Good",
        "Construction": "Good",
        "Versatility": "",
        "Value": "Good",
        "Colors": "",
        "Description": "Resonable option for buddy strapping needs.",
        "URL": ""
    },
    {
        "Comfort": "Good",
        "Value": "Good",
        "Style": "Good",
        "Ease_don_doff": "Great",
        "Colors": "Red, Blue, Green",
        "Description": "Reliable quality. Industry standard.",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/81YKIO53CyL._SL1500_.png?alt=media&token=5c8eca2c-e81d-4a2f-81e2-29dce5ea4399",
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/15820.png?alt=media&token=05c0df8c-0c7b-47f3-a41a-95257ba1b4c8",
        "Durability": "Good",
        "Washability": "N/A",
        "Versatility": "N/A",
        "IDX": "B07CX1SJTJ",
        "Rating": 5,
        "Price": "$6.45",
        "URL": "https://www.amazon.com/3M-Coban-Self-Adherent-Rolls/dp/B07CX1SJTJ/ref=sr_1_6?dchild=1&keywords=coban&qid=1594082270&sr=8-6",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 760000000
        },
        "Photo_3": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/CobanSelfAdhesiveWrapImage3.png?alt=media&token=7d70f664-cf48-472c-a60b-9a04457854e7",
        "Name": "3M<sup>&trade;</sup> Coban<sup>&trade;</sup> 1\" Self-Adherent Wrap",
        "Construction": "Good",
        "Affilate_URL": "https://amzn.to/3ivMjGd"
    },
    {
        "Price": "$39.17",
        "Style": "",
        "URL": "https://www.amazon.com/Hely-Weber-Knuckle-Orthosis-Left/dp/B00N3IYJH6",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 765000000
        },
        "Construction": "",
        "Affilate_URL": "https://amzn.to/2H3MZox",
        "Photo_2": "",
        "Rating": 0,
        "Durability": "",
        "IDX": "B00N3IYJH6",
        "Photo_3": "",
        "Description": "",
        "Photo_1": "https://images-na.ssl-images-amazon.com/images/I/41iIdRWkvKL._AC_.jpg",
        "Name": "Hely & Weber<sup>&reg;</sup> TKO&reg; (The Knuckle Orthosis)",
        "Versatility": "No (radial gutter splint - use opposite hand brace)",
        "Washability": "Hand wash",
        "Ease_don_doff": "",
        "Comfort": "",
        "Value": "",
        "Colors": "Black/green"
    },
    {
        "Price": "$98.49",
        "Versatility": "No",
        "Durability": "Good",
        "Description": "High quality option for CMC OA.  Splint does require proper fitting and is useful for a slightly specific presentation (narrower application than, say, comfort cool thumb spica)",
        "Washability": "Hand wash",
        "Rating": 5,
        "Photo_3": "",
        "Construction": "Good",
        "Comfort": "Good",
        "Ease_don_doff": "Good",
        "Affilate_URL": "https://amzn.to/2ZwLwgL",
        "Name": "Push<sup>&reg;</sup> MetaGrip<sup>&reg;</sup> CMC Thumb Orthosis",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/Metagrip-thumb.png?alt=media&token=47245b74-73dc-4bcb-88f1-ee468016dabc",
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/41Rh06kYtPL.png?alt=media&token=7b234ce7-1afc-45b6-b7bd-f2d6e2d874d9",
        "Style": "Good",
        "Value": "Expensive",
        "IDX": "B00GTXAFLG",
        "URL": "https://www.amazon.com/MetaGrip-Thumb-Brace-Relief-Osteoarthritis/dp/B00GTXAFLG",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 764000000
        },
        "Colors": "Grey"
    },
    {
        "Colors": "Black, grey",
        "Photo_3": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/81p%2BKftSZTL._AC_SL1500_.png?alt=media&token=5118a4b7-3fa2-49bc-aea6-b19c1e1e8447",
        "Rating": 5,
        "Construction": "Good",
        "Ease_don_doff": "Excellent",
        "Style": "Good",
        "URL": "https://www.amazon.com/3-Point-Products-Jammed-Fingers-Package/dp/B01DME2TBI/ref=sr_1_9?dchild=1&keywords=buddy%2Bstraps&qid=1594084051&sr=8-9&th=1",
        "Value": "Good",
        "Versatility": "Yes",
        "Name": "3pp<sup>&reg</sup> Buddy Loops<sup>&reg;</sup>",
        "Washability": "Hand wash",
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/41FXCEslwgL._AC_.png?alt=media&token=502b31e8-1c1b-4602-84a5-0a6627494580",
        "Comfort": "Excellent",
        "Price": "$14.97/pack of 5",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/3pointBuddyLoops%20copy.png?alt=media&token=3ceac462-2985-4802-afbd-8fc031c640a2",
        "IDX": "B01DME2TBI",
        "Durability": "Good, although material has a limited lifespan by definition.",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 762000000
        },
        "Description": "Quality product.  Dependable for durability and construction.",
        "Affilate_URL": "https://amzn.to/3hBY78I"
    },
    {
        "Ease_don_doff": "Good",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/71Y4AXspAsL._AC_SL1500_.png?alt=media&token=d83b2c2e-3fc8-40a8-976b-61314c78cf73",
        "Versatility": "No",
        "Durability": "Good",
        "URL": "https://www.amazon.com/FLA-Adjustable-Right-Thumb-Brace/dp/B01BOTTFRE/ref=sr_1_5?dchild=1&gclid=CjwKCAjwmMX4BRAAEiwA-zM4Jp5o0XbVRfSCJLp8dpevwZh9wDiMl0c313bkAI8VgH4-9we6_yPbaxoCnCAQAvD_BwE&hvadid=198209548796&hvdev=c&hvlocphy=9004077&hvnetw=g&hvqmt=e&hvrand=8629601492731654121&hvtargid=kwd-320636170540&hydadcr=21886_9712128&keywords=fla+adjustable+3d+thumb+brace&qid=1594997520&sr=8-5&tag=googhydr-20",
        "Style": "Lightweight, open design",
        "Photo_2": "https://images-na.ssl-images-amazon.com/images/I/71mBvmVzT0L._AC_SL1500_.jpg",
        "Affilate_URL": "https://amzn.to/3ixj85p",
        "Comfort": "Good",
        "Name": "FLA<sup>&reg;</sup> 3D Adjustable Thumb Brace",
        "Colors": "Grey/black",
        "IDX": "B01BOTTFRE",
        "Description": "Well tolerated, comfortable support for thumb CMC and MP joints.  Useful for OA and low grade sprains.",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 764000000
        },
        "Washability": "Machine washable",
        "Value": "Good",
        "Photo_3": "https://images-na.ssl-images-amazon.com/images/I/61FaV%2B-TEjL._AC_SL1500_.jpg",
        "Construction": "Good.  +Breathability",
        "Price": "$42.40",
        "Rating": 4
    },
    {
        "Rating": 5,
        "Construction": "Good",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 765000000
        },
        "Versatility": "No",
        "Affilate_URL": "https://amzn.to/3iHvhVq",
        "Photo_2": "",
        "Washability": "Hand wash",
        "Photo_3": "",
        "Description": "Well-tolerated, quality, versatile splint for many conditions.",
        "Style": "Complete sleeve design with D-rings.",
        "Value": "Excellent",
        "Colors": "Black",
        "Ease_don_doff": "Good.",
        "URL": "https://www.amazon.com/Comfort-Wrist-Splint-D-Ring-Medium/dp/B003DQILZE",
        "Price": "$23.99",
        "Comfort": "Good",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/41nQ3sAUWvL._AC_%20.png?alt=media&token=1da61dc9-e09f-4275-acc1-b75f87dd7b36",
        "IDX": "B003DQILZE",
        "Durability": "Good",
        "Name": "Comfort Cool<sup>&reg;</sup> Thumb CMC Restriction Splint"
    },
    {
        "Washability": "Hand wash",
        "Rating": 5,
        "IDX": "ManutecCMC",
        "Photo_1": "https://ssl.cdn.ncmedical.com/items/fullsize/2019_07_26_08_41_43__12_m780i_1_manutec_web_4_vnd_w_19.jpg",
        "Name": "Manutec&reg; Fix Rizart Plus CMC Thumb Orthosis (Orliman<sup>&reg;</sup>)",
        "Colors": "Grey/black",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 764000000
        },
        "URL": "https://www.ncmedical.com/item_3860.html#:~:text=Manutec%C2%AE%20Fix%20Rizart%20Plus%20CMC%20Thumb%20Orthosis,-Click%20an%20image&text=Keeps%20thumb%20CMC%20in%20a,the%20functionality%20of%20the%20hand.",
        "Durability": "Good",
        "Ease_don_doff": "Good",
        "Versatility": "No",
        "Price": "$59.00",
        "Photo_2": "https://ssl.cdn.ncmedical.com/items/fullsize/2020_02_19_13_09_16__7_NC36850_.JPG",
        "Affilate_URL": "",
        "Description": "High quality option for localized CMC joint support",
        "Style": "Good",
        "Comfort": "Good. Nice attention to bar in webspace",
        "Construction": "Good",
        "Value": "Good.  Slightly less expensive than Push MetaGrip",
        "Photo_3": "https://ssl.cdn.ncmedical.com/items/fullsize/2019_07_26_08_41_34__10_m780i_1_manutec_web_2_vnd_w_19.jpg"
    },
    {
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/71CbkywBcfL._AC_SL1500_.png?alt=media&token=c2687953-f301-461c-8cef-adba3a24ba66",
        "Affilate_URL": "https://amzn.to/32rFMql",
        "Ease_don_doff": "Good",
        "Photo_3": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/71BDJ6hE85L._AC_SL1500_.png?alt=media&token=b16330c4-d23e-4487-be0d-702c2db8d3e9",
        "Versatility": "Yes",
        "Style": "Fair",
        "Price": "$12.99/pack of ten",
        "Construction": "Fair",
        "Name": "BBTO Mallet Finger Support Finger Splint",
        "Comfort": "Good",
        "IDX": "B08422X1HC",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 762000000
        },
        "Colors": "Peach",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/719Pkkl14fL._AC_SL1500_.png?alt=media&token=ea395817-8634-4666-956f-523744425d7f",
        "URL": "https://www.amazon.com/Protector-Adjustable-Immobilizer-Basketball-Protection/dp/B08422X1HC/",
        "Value": "Good",
        "Washability": "Hand wash",
        "Description": "Resonable, short term option for a mallet finger or DIP joint injury.  Strap closure not durable for long term use",
        "Rating": 3,
        "Durability": "Fair"
    },
    {
        "Washability": "Hand wash",
        "Price": "$28.26",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 764000000
        },
        "Description": "A quality option for this diagnosis",
        "Photo_2": "https://www.hely-weber.com/images/products/2804-6.jpg",
        "URL": "https://www.hely-weber.com/upper-extremities/thumbs/175-cmc-controller-plus-2804.html",
        "Durability": "Good",
        "Photo_3": "https://www.hely-weber.com/images/products/2804-2.jpg",
        "Rating": 4,
        "Name": "Hely & Weber<sup>&reg;</sup> CMC Controller Plus&reg",
        "Style": "Good",
        "Affilate_URL": "",
        "Value": "Good",
        "Colors": "Black",
        "IDX": "HWCMC",
        "Comfort": "Good",
        "Construction": "Good",
        "Photo_1": "https://www.hely-weber.com/images/products/2804-3.jpg",
        "Versatility": "No",
        "Ease_don_doff": "Good"
    },
    {
        "Rating": 5,
        "Value": "Good",
        "Photo_1": "https://images-na.ssl-images-amazon.com/images/I/61QIYL%2BOMkL._AC_SL1029_.jpg",
        "Affilate_URL": "https://amzn.to/3iquS9U",
        "Ease_don_doff": "Good",
        "Construction": "Good",
        "Photo_3": "https://pushsports.eu/images/default-source/products/wrist-support-4.jpg?sfvrsn=72538e2a_2",
        "IDX": "B074BH7FY6",
        "Washability": "Machine washable",
        "Comfort": "Good",
        "Name": "Push Sports Wrist Support",
        "URL": "https://www.amazon.com/Push-Sports-Wrist-Support-Compression/dp/B074BH7FY6",
        "Price": "$22.50",
        "Style": "Clever design",
        "Description": "Clever design for circumferential support of the DRUJ that does not limit the radiocarpal joint",
        "Durability": "Very good",
        "Versatility": "No",
        "Photo_2": "https://pushsports.eu/images/default-source/products/wrist-support-3.jpg?sfvrsn=e56d73c5_2",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 764000000
        },
        "Colors": "Grey/yellow"
    },
    {
        "Comfort": "Good",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/3pointLeftThumbSling.png?alt=media&token=256386c7-10b5-4b9c-a600-682b67720c8f",
        "Style": "Good",
        "Value": "Good",
        "URL": "https://www.amazon.com/Point-Products-Thumsling-Small-Medium/dp/B0069RJC0M",
        "Construction": "Good",
        "Ease_don_doff": "Good",
        "Rating": 4,
        "Description": "Versatile, comfortable option.",
        "Versatility": "No",
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/3pointLeftThumbSling.png?alt=media&token=256386c7-10b5-4b9c-a600-682b67720c8f",
        "Affilate_URL": "https://amzn.to/2RscGAM",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 764000000
        },
        "Price": "$27.99",
        "Name": "3pp<sup>&reg;</sup> ThumSling<sup>&reg;</sup>",
        "Colors": "Grey/black",
        "Washability": "Hand wash",
        "IDX": "B0069RJC0M/B00GSYG934",
        "Photo_3": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/3pointLeftThumbSling.png?alt=media&token=256386c7-10b5-4b9c-a600-682b67720c8f",
        "Durability": "Good"
    },
    {
        "Durability": "Likely good",
        "URL": "https://www.walmart.com/ip/Mueller-Green-Fitted-Wrist-Brace-Black-One-Size-Fits-Most-Right-Hand/650994086?wmlspartner=wlpa&selectedSellerId=0&&adid=22222222227282341982&wl0=&wl1=g&wl2=c&wl3=341268222409&wl4=pla-675982997212&wl5=9073479&wl6=&wl7=&wl8=&wl9=pla&wl10=8175035&wl11=online&wl12=650994086&veh=sem",
        "Washability": "Hand wash. Non-removable strut.",
        "Affilate_URL": "",
        "Value": "Good",
        "Comfort": "Good",
        "Rating": 5,
        "IDX": "WMMUELLERWRIST",
        "Price": "$14.84 ",
        "Versatility": "No",
        "Ease_don_doff": "Easy",
        "Description": "Solid, versatile option.",
        "Construction": "Good quality",
        "Style": "Good",
        "Photo_3": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/9c18706c-ce0a-4e56-8c38-aeb8288dec69_1.d5c988d657bd32793f28fed01c392452.png?alt=media&token=e8263059-ee55-4177-b4ea-2cf94d0773bb",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 763000000
        },
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/b9aeddb8-eb67-4842-a19e-8486c4082240_2.4a1f1661eea2e09779e1ea73a66a5a48.png?alt=media&token=48bb8ffe-a58c-4adf-8a44-b6274f259460",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/ec994612-6cda-4bd4-9983-432391ebc1e9_2.f90d53976a94227a8696d4f0ac3c91a4.png?alt=media&token=676a9abc-c275-4976-9664-83c9a546d7b4",
        "Colors": "Black/Green",
        "Name": "Mueller<sup>&reg;</sup> Green Fitted Wrist Brace"
    },
    {
        "Colors": "Black/grey",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 765000000
        },
        "Ease_don_doff": "Very good",
        "Rating": 5,
        "Photo_3": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/61m5dRnPXSL._SL1500_.png?alt=media&token=b2bb81f1-203f-4dcf-8912-32e71483475a",
        "URL": "https://www.amazon.com/Bullseye-Wrist-Band-Fibrocartilage-Instability/dp/B07BBL7Y19/ref=sr_1_2?dchild=1&gclid=Cj0KCQjw9b_4BRCMARIsADMUIyqAPxK9TOxRybW8EV8i5ll4CMt_WhZiMNwiGRUfe-EL6kFOve-fZz4aAgXrEALw_wcB&hvadid=410022199410&hvdev=c&hvlocphy=9073479&hvnetw=g&hvqmt=e&hvrand=3609683391490688790&hvtargid=kwd-472713718261&hydadcr=21877_11241855&keywords=bullseye+wrist+brace&qid=1594932542&sr=8-2&tag=googhydr-20",
        "Durability": "Very good",
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/BullsEyeBandWristBand.png?alt=media&token=5edc7729-67f6-4650-bd2d-84dc0a6aab31",
        "Construction": "Very good",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/61trHPmVRBL._SL1500_.png?alt=media&token=12ed35f4-b867-4abe-876f-e4442890e064",
        "Affilate_URL": "https://amzn.to/2RoGPAW",
        "IDX": "B07BBL7Y19",
        "Value": "Good",
        "Description": "Well-made, comfortable, dependable quality.",
        "Washability": "Hand wash",
        "Price": "$39.00",
        "Style": "Very good",
        "Name": "Bullseye Brand Wrist Band",
        "Versatility": "Yes",
        "Comfort": "Very good"
    },
    {
        "Photo_1": "https://images-na.ssl-images-amazon.com/images/I/61BBM1P1tkL._AC_SL1500_.jpg",
        "Durability": "",
        "Description": "",
        "Photo_3": "https://images-na.ssl-images-amazon.com/images/I/61qH5iDTd3L._AC_SL1500_.jpg",
        "Rating": 0,
        "URL": "https://www.amazon.com/Soles-Neoprene-Boxer-Metacarpal-Splint/dp/B07RZ7NX17/ref=sr_1_5?dchild=1&keywords=ulnar+gutter+splint&qid=1594917106&sr=8-5",
        "Washability": "Hand wash",
        "Value": "",
        "Style": "",
        "Price": "$18.99",
        "IDX": "B07RZ7NX17",
        "Affilate_URL": "https://amzn.to/3mvB3vY",
        "Photo_2": "https://images-na.ssl-images-amazon.com/images/I/6169sHQ9hjL._AC_SL1500_.jpg",
        "Versatility": "Yes",
        "Colors": "Black",
        "Construction": "",
        "Name": "Soles<sup>&reg;</sup> Boxer Break Metacarpal Splint Brace",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 763000000
        },
        "Comfort": "",
        "Ease_don_doff": ""
    },
    {
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/HW_DEQ_FRONT.png?alt=media&token=daaa4622-cf41-4081-ad9c-460dbe2d2448",
        "Name": "Hely & Weber<sup>&reg;</sup> deQ<sup>&reg;</sup> Thumb Orthosis ",
        "URL": "https://www.hely-weber.com/upper-extremities/thumbs/205-deq-thumb-orthosis-4813.html",
        "Style": "Very good.  Light structure",
        "Photo_3": "",
        "IDX": "HWDEQ4813",
        "Durability": "Good",
        "Construction": "Good",
        "Description": "Well-designed, quality splint",
        "Rating": 5,
        "Washability": "Hand wash",
        "Versatility": "Yes",
        "Photo_2": "",
        "Price": "$35.35",
        "Comfort": "Good",
        "Ease_don_doff": "Good",
        "Colors": "Black",
        "Value": "Good",
        "Affilate_URL": "",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 765000000
        }
    },
    {
        "Affilate_URL": "https://amzn.to/3hv4KcL",
        "Name": "3pp<sup>&reg;</sup> ThumSaver<sup>&trade;</sup> MP",
        "Comfort": "Fair/poor",
        "Photo_2": "https://www.ohmyarthritis.com/thumsaver-mp-image-2.jpg",
        "Durability": "Fair",
        "Colors": "Peach",
        "Ease_don_doff": "Good",
        "Value": "Good",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 763000000
        },
        "Versatility": "No",
        "URL": "https://www.amazon.com/Point-Products-Thumsaver-Small-Ounce/dp/B002HRTDI4?th=1",
        "Description": "Not recommended. I don't like this design. I would not include even as second tier.",
        "Rating": 2,
        "Price": "$36.99",
        "Photo_1": "https://www.ohmyarthritis.com/thumsaver-mp-kCup.jpg",
        "Washability": "Hand wash",
        "Photo_3": "https://www.ohmyarthritis.com/thumsaver-mp-main-image-ns.jpg",
        "Construction": "Fair/Poor",
        "Style": "Fair",
        "IDX": "B00EIRT6I8"
    },
    {
        "Construction": "Good.  A touch complicated",
        "Colors": "Black",
        "URL": "https://www.amazon.com/Hely-Weber-Squeeze-Ulnar-Compression/dp/B0068QQZ4U/ref=sr_1_1?dchild=1&keywords=B0068QQZ4U&linkCode=sl2&linkId=c202b40ac316316d0a358673b2e0bb37&qid=1599849400&sr=8-1&tag=armadillomd-20",
        "Value": "Good",
        "Rating": 4,
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/31TTGPqGhhL._AC_.png?alt=media&token=2de772d7-f4a7-4e9a-a04b-37480a3d27b9",
        "Versatility": "Yes",
        "Washability": "Hand wash",
        "Ease_don_doff": "Good",
        "Name": "Hely & Weber<sup>&reg;</sup> \"Squeeze\" Ulnar Compression Wrap",
        "IDX": "B0068QQZ4U",
        "Comfort": "Good",
        "Affilate_URL": "https://amzn.to/2RmMebY",
        "Price": "$28.97",
        "Durability": "Good",
        "Description": "Comfortable splint with good design features.  Slightly more complex to don/doff",
        "Photo_3": "",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 765000000
        },
        "Style": "Good",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/31MqgI%2BDe3L._AC_.png?alt=media&token=f8afaeb7-4529-413c-863a-788d2d33a34f"
    },
    {
        "Colors": "",
        "Rating": 3,
        "Name": "Sumifun Finger Buddy Straps",
        "Construction": "Fair",
        "Washability": "Hand wash",
        "IDX": "B07R586JK4",
        "Durability": "Good, although material has a limited lifespan by definition.",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 763000000
        },
        "Ease_don_doff": "Good",
        "Photo_3": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/716KY4TSRwL._AC_SL1500_.png?alt=media&token=b07d2cce-cfba-46af-9839-8a743e7165ee",
        "Affilate_URL": "https://amzn.to/3mpd2Xs",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/SumifunBuddyStraps.png?alt=media&token=7d0e0e0e-7da3-44a8-aeac-9933d2b99a91",
        "Description": "Consider when swimming a cheaper option for a short duration.  Good option for shorter duration use. Closure not as long lasting, materials/design not as sophisticated.",
        "Price": "$9.99/pack of 10",
        "Comfort": "Good",
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/71-HlrbRrHL._AC_SL1500_.png?alt=media&token=29901f78-94f5-4181-a222-550b8fdd0b85",
        "URL": "",
        "Versatility": "Yes",
        "Style": "Good",
        "Value": "Good"
    },
    {
        "Affilate_URL": "",
        "Value": "Expensive",
        "Description": "Well-designed wrist support with volar rigidity.  Higher end option",
        "Rating": 5,
        "Photo_1": "",
        "Versatility": "Yes",
        "Ease_don_doff": "Very good",
        "Photo_2": "",
        "Colors": "",
        "Name": "Push&reg; Med Wrist Brace",
        "Durability": "Very good",
        "IDX": "BLWS",
        "Style": "Excellent.",
        "Comfort": "Very good",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 765000000
        },
        "URL": "",
        "Construction": "Excellent.  Very high end option",
        "Photo_3": "",
        "Price": "$58.99",
        "Washability": "Machine Wash gentle cycle"
    },
    {
        "Durability": "Likely good",
        "Ease_don_doff": "Good.  D-ring + sleeve closure",
        "Photo_3": "https://images-na.ssl-images-amazon.com/images/I/51WIh8jug6L._AC_.jpg",
        "Style": "",
        "Versatility": "Yes",
        "Photo_2": "https://images-na.ssl-images-amazon.com/images/I/5148DG9QjpL._AC_.jpg",
        "Photo_1": "https://images-na.ssl-images-amazon.com/images/I/51M4WNYNh1L._AC_.jpg",
        "URL": "https://www.amazon.com/Developed-Premium-Support-Handbook-Suitable/dp/B072392YGD",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 765000000
        },
        "Name": "Dr. Arthritis Wrist Support Brace",
        "Colors": "Black",
        "Description": "Basic wrist wrap with copper lining.",
        "Affilate_URL": "https://amzn.to/3hsOQzG",
        "Washability": "Hand wash.  Removable bar.",
        "Comfort": "Good but bulky",
        "IDX": "B072392YGD",
        "Value": "Good",
        "Price": "$12.95",
        "Construction": "Good",
        "Rating": 3
    },
    {
        "Description": "Generic option with reasonable performance for basic demands.",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 763000000
        },
        "Style": "",
        "Rating": 4,
        "Durability": "",
        "Price": "$9.99/pack of 12",
        "Washability": "No wash",
        "Versatility": "Yes",
        "IDX": "B07SXBJSQF",
        "Affilate_URL": "https://amzn.to/35yOuVF",
        "Photo_1": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/81fG8iNE10L._AC_SL1500_.png?alt=media&token=6c37aad9-166c-4f84-ae12-f968a4b2fbc6",
        "Colors": "Red, orange, yellow, purple, phosphor, fluorescent green, black, blue, white (1 roll per color),skin color x 3 roll.",
        "Value": "",
        "Name": "Cherish Tea 12 Pack 1\" Self-Adhesive Elastic Bandage Wrap",
        "Construction": "",
        "URL": "https://www.amazon.com/gp/product/B07SXBJSQF/ref=ox_sc_act_title_2?smid=A1E1VCYULRZO0D&psc=1",
        "Photo_3": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/71uubE%2BbWNL._AC_SL1500_.png?alt=media&token=5cdb65d8-7b82-44d1-af33-7e1076956521",
        "Comfort": "",
        "Photo_2": "https://firebasestorage.googleapis.com/v0/b/armadillomd.appspot.com/o/51SOf3IMDJL._AC_SL1189_%20copy.png?alt=media&token=0494c90b-7935-4962-91ad-2c871a40ab9d",
        "Ease_don_doff": ""
    },
    {
        "URL": "https://www.amazon.com/Hely-Weber-Neoprene-Wristlet-3814/dp/B00EIRGHIU",
        "Colors": "Black",
        "Comfort": "Good",
        "Photo_3": "https://images-na.ssl-images-amazon.com/images/I/41ckSWeCBeL._AC_.jpg",
        "Durability": "Good",
        "Rating": 4,
        "Name": "Hely & Weber<sup>&reg;</sup> Neoprene Wristlet",
        "Style": "Easy design",
        "Versatility": "Yes",
        "Photo_2": "https://images-na.ssl-images-amazon.com/images/I/41AAfLd2rVL._AC_.jpg",
        "Ease_don_doff": "Good",
        "Washability": "Hand wash",
        "Description": "Versatile, well-tolerated light soft support for low-grade wrist injuries",
        "Affilate_URL": "https://amzn.to/3bWVRHK",
        "IDX": "B00EIRGHIU",
        "Price": "$19.76",
        "Value": "Good",
        "Construction": "Good",
        "Photo_1": "https://images-na.ssl-images-amazon.com/images/I/41sRudyEyhL._AC_.jpg",
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 764000000
        }
    },
    {
        "createdAt": {
            "seconds": 1597878028,
            "nanoseconds": 763000000
        },
        "Value": "Good",
        "Rating": 3,
        "Affilate_URL": "https://amzn.to/2DXUbkC",
        "Photo_2": "",
        "Photo_3": "",
        "Name": "Vive Wrist Splint",
        "Versatility": "Yes",
        "Ease_don_doff": "Fair",
        "Price": "",
        "Description": "Reasonable budget alternative.  Right/Left exchange requires some effort.",
        "IDX": "B00VXEF46M",
        "Comfort": "Fair",
        "Construction": "Fair",
        "Washability": "Hand Wash",
        "Durability": "Fair.  Hook velcro is not ideal",
        "Style": "Fair",
        "URL": "https://www.amazon.com/Vive-Wrist-Brace-Compression-Tendinitis/dp/B00VXEF46M/ref=sr_1_1?dchild=1&keywords=B00VXEF46M&qid=1596671409&sr=8-1",
        "Colors": "Gray",
        "Photo_1": ""
    }
];

export {
    products
}